<script setup>
import { onMounted, onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';
import useBillingRates from '../../../../hooks/useBillingRates';
import pageTitle, { setPageTitle } from '@/utils/pageTitle';
import { useToast } from "vue-toastification";

const rateId = ref(null)
const route = useRouter()
const toast = useToast()


const { rate, fetchRateWithId } = useBillingRates();

const props = defineProps({
    params: Object
});

onBeforeMount(() => {
    rateId.value = route.currentRoute.value.params.id;
    fetchCurrentRateData(rateId.value);
})

onMounted(() => {
    setPageTitle("View Default Billing Rate");
})

const fetchCurrentRateData = async (id) => {
    await fetchRateWithId(id);
}

const disable = async () => {
    //TODO: disable
    toast.success("Rate Updated Successfully.");
    route.push('/billings/default-billing-rates');
}

</script>

<template>
    <v-card-title>{{ pageTitle }}</v-card-title>
    <v-card flat class="mx-4 mt-4">
        <v-card-text>
            <v-row align="center">
                <v-col cols="6">
                    <label for="category"><strong>Category</strong></label>
                </v-col>
                <v-col cols="6">
                    {{ rate.category }}
                </v-col>

                <v-divider />

                <v-col cols="6">
                    <label for="code"><strong>Code</strong></label>
                </v-col>
                <v-col cols="6">
                    {{ rate.code }}
                </v-col>

                <v-divider />

                <v-col cols="6">
                    <label><strong>Description</strong></label>
                </v-col>
                <v-col cols="6">
                    {{ rate.description }}
                </v-col>

                <v-divider />

                <v-col cols="6">
                    <label><strong>Fee</strong></label>
                </v-col>
                <v-col cols="6">
                    {{ rate.cost }}
                </v-col>

                <v-divider />

                <v-col cols="6">
                    <label><strong>Our Cost</strong></label>
                </v-col>
                <v-col cols="6">
                    {{ rate.our_cost }}
                </v-col>

                <v-divider />

                <v-col cols="6">
                    <label><strong>Quantity In Stock</strong></label>
                </v-col>
                <v-col cols="6">
                    {{ rate.qty }}
                </v-col>

                <v-divider />

                <v-col cols="6">
                    <label><strong>Quick Book</strong></label>
                </v-col>
                <v-col cols="6">
                    {{ rate.quickbook }}
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>

    <v-card-actions class="d-flex justify-center mx-2 mt-4">
        <v-btn variant="flat" color="red" @click="disable()">
            Disable
        </v-btn>
    </v-card-actions>
</template>